import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  AuthFlowType,
  RespondToAuthChallengeCommand,
  ChallengeNameType,
  AssociateSoftwareTokenCommand,
  VerifySoftwareTokenCommand,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommand,
  SignUpCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import config from "../config.json";
import axios from "axios";

export const cognitoClient = new CognitoIdentityProviderClient({
  region: config.region,
});

export const exchangeAuthorizationCodeForTokens = async (
  authorizationCode,
  callbackUrl
) => {
  const url = `${config.cognitoDomain}/oauth2/token`;
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const body = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: config.clientId,
    code: authorizationCode,
    redirect_uri: callbackUrl,
  }).toString();

  try {
    const response = await axios.post(url, body, { headers });
    console.log(response);
    if (response.data.access_token)
      localStorage.setItem("accessToken", response.data.access_token);
    if (response.data.id_token)
      localStorage.setItem("idToken", response.data.id_token);

    if (response.data.refresh_token) {
      localStorage.setItem("refreshToken", response.data.refresh_token);
    }
  } catch (err) {
    console.log(err);
    return false;
  }

  return true;
};

export const signIn = async (username, password) => {
  const params = {
    AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
    ClientId: config.clientId,
    AuthParameters: {
      USERNAME: username,
      PASSWORD: password,
    },
  };

  try {
    console.debug("Bezig met inloggen...");
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    console.log(response);

    const { ChallengeName, Session, AuthenticationResult } = response;

    if (AuthenticationResult) {
      console.log("Login succesvol zonder uitdaging.");
      // Tokens worden teruggestuurd, inloggen zonder MFA
      saveTokens(AuthenticationResult);
      return {
        AuthenticationResult,
      };
    }

    if (ChallengeName) {
      console.log(`Challenge ontvangen: ${ChallengeName}`);
      return {
        ChallengeName,
        Session,
      };
    }

    // Als geen van beide wordt teruggestuurd, gooi een fout
    throw new Error("Onverwachte response van Cognito.");
  } catch (error) {
    console.error("Fout bij inloggen:", error);
    throw error;
  }
};

export const googleSignIn = async () => {};

export const saveTokens = (authenticationResult, rememberMe = false) => {
  console.log(authenticationResult);
  const { IdToken, AccessToken, RefreshToken } = authenticationResult;

  if (IdToken) localStorage.setItem("idToken", IdToken);
  if (AccessToken) localStorage.setItem("accessToken", AccessToken);

  if (RefreshToken) {
    const storage = rememberMe ? localStorage : sessionStorage;
    storage.setItem("refreshToken", RefreshToken);
  }
};

export const signUp = async (username, password) => {
  const params = {
    ClientId: config.clientId,
    Username: username,
    Password: password,
  };

  try {
    const command = new SignUpCommand(params);
    const response = await cognitoClient.send(command);
    return response;
  } catch (error) {
    console.error("Fout bij aanmelden:", error);
    throw error;
  }
};

export const signOut = () => {
  console.log("Gebruiker uitloggen...");
  localStorage.removeItem("idToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  sessionStorage.removeItem("idToken");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("refreshToken");
  console.log("Succesvol uitgelogd.");
};

export const refreshAccessToken = async (refreshToken, rememberMe = false) => {
  const params = {
    AuthFlow: AuthFlowType.REFRESH_TOKEN_AUTH,
    ClientId: config.clientId,
    AuthParameters: {
      REFRESH_TOKEN: refreshToken,
    },
  };

  try {
    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command);
    const authenticationResult = response.AuthenticationResult;

    if (authenticationResult) {
      // Sla de nieuwe tokens op in localStorage
      saveTokens(authenticationResult, rememberMe);
    } else {
      throw new Error("Geen Authentication Result ontvangen van Cognito.");
    }

    return authenticationResult;
  } catch (error) {
    console.error("Error refreshing access token: ", error);
    throw error;
  }
};

export const ensureValidToken = async () => {
  console.log("Controleer of het toegangstoken geldig is...");
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken =
    localStorage.getItem("refreshToken") ||
    sessionStorage.getItem("refreshToken");

  const isAccessTokenExpired = checkTokenExpiry(accessToken);

  if (isAccessTokenExpired) {
    try {
      console.log("Toegangstoken is verlopen, probeer te verversen...");
      await refreshAccessToken(refreshToken);
    } catch (error) {
      console.error("Kan token niet verversen:", error);
      signOut();
      window.location.href = "/auth/sign-in";
    }
  } else {
    console.log("Toegangstoken is nog geldig.");
  }
};

const checkTokenExpiry = (token) => {
  if (!token) return true;

  const payload = JSON.parse(atob(token.split(".")[1]));
  const expiryTime = payload.exp * 1000;

  return Date.now() > expiryTime;
};

export const initiateForgotPassword = async (username) => {
  const command = new ForgotPasswordCommand({
    ClientId: config.clientId,
    Username: username,
  });

  try {
    console.log(`Initiating password reset for user: ${username}...`);
    const response = await cognitoClient.send(command);
    console.log("Password reset initiation successful.");
    //   return {
    //     "CodeDeliveryDetails": {
    //         "AttributeName": "email",
    //         "DeliveryMedium": "EMAIL",
    //         "Destination": "a***@l***"
    //     }
    // };
    return response;
  } catch (error) {
    console.error("Error initiating password reset:", error);
    throw error;
  }
};

export const confirmForgotPassword = async (
  username,
  verificationCode,
  newPassword
) => {
  const command = new ConfirmForgotPasswordCommand({
    ClientId: config.clientId,
    Username: username,
    ConfirmationCode: verificationCode,
    Password: newPassword,
  });

  try {
    console.log(`Confirming new password for user: ${username}...`);
    const response = await cognitoClient.send(command);
    console.log("New password set successfully.");
    return response;
  } catch (error) {
    console.error("Error confirming new password:", error);
    throw error;
  }
};

export const RespondToMfaChallenge = async (
  username,
  mfaCode,
  session,
  rememberMe
) => {
  const command = new RespondToAuthChallengeCommand({
    ChallengeName: ChallengeNameType.SOFTWARE_TOKEN_MFA,
    ChallengeResponses: {
      SOFTWARE_TOKEN_MFA_CODE: mfaCode,
      USERNAME: username,
    },
    ClientId: config.clientId,
    Session: session,
  });

  try {
    console.log("Bezig met verwerken van MFA-uitdaging...");
    const response = await cognitoClient.send(command);
    const authenticationResult = response.AuthenticationResult;

    if (authenticationResult) {
      // Sla de nieuwe tokens op in localStorage
      saveTokens(authenticationResult, rememberMe);
    } else {
      throw new Error("Geen Authentication Result ontvangen van Cognito.");
    }
    console.log("MFA-uitdaging succesvol verwerkt.");
    return authenticationResult;
  } catch (error) {
    console.error("Fout bij het verwerken van de MFA-uitdaging:", error);
    throw error;
  }
};

export const RespondToNewPasswordChallenge = async (
  username,
  password,
  session
) => {
  const command = new RespondToAuthChallengeCommand({
    ChallengeName: ChallengeNameType.NEW_PASSWORD_REQUIRED,
    ChallengeResponses: {
      USERNAME: username,
      NEW_PASSWORD: password,
    },
    ClientId: config.clientId,
    Session: session,
  });

  try {
    console.log("Bezig met verwerken van nieuw wachtwoord uitdaging...");
    const response = await cognitoClient.send(command);
    const authenticationResult = response.AuthenticationResult;
    if (authenticationResult) {
      // Sla de nieuwe tokens op in localStorage
      saveTokens(authenticationResult);
    } else {
      throw new Error("Geen Authentication Result ontvangen van Cognito.");
    }
    console.log("Nieuw wachtwoord succesvol ingesteld.");
    return response;
  } catch (error) {
    console.error(
      "Fout bij het verwerken van de nieuw wachtwoord uitdaging:",
      error
    );
    throw error;
  }
};

export const RespondToMfaSetupChallenge = async (session, username) => {
  if (!session) {
    throw new Error(
      "De sessie is ongeldig of verlopen. Probeer opnieuw in te loggen."
    );
  }

  const command = new AssociateSoftwareTokenCommand({
    Session: session,
  });

  try {
    console.log("Bezig met instellen van MFA...");
    const response = await cognitoClient.send(command);
    const authenticationResult = response.AuthenticationResult;
    if (authenticationResult) {
      // Sla de nieuwe tokens op in localStorage
      saveTokens(authenticationResult, rememberMe);
    } else {
      throw new Error("Geen Authentication Result ontvangen van Cognito.");
    }
    const qrCodeUrl = `otpauth://totp/${username}?secret=${response.SecretCode}&issuer=${config.appName}`;
    console.log("MFA succesvol ingesteld.");
    return [response.Session, qrCodeUrl, response.SecretCode];
  } catch (error) {
    console.error("Fout bij het instellen van MFA:", error);
    throw error;
  }
};

export const VerifyMfaCode = async (mfaCode, session, username, rememberMe) => {
  const verifyCommand = new VerifySoftwareTokenCommand({
    UserCode: mfaCode,
    Session: session,
  });

  try {
    console.log("Bezig met verifiëren van MFA-code...");
    const response = await cognitoClient.send(verifyCommand);

    if (response.Status === "SUCCESS") {
      console.log("MFA-code succesvol geverifieerd.");
      const challengeCommand = new RespondToAuthChallengeCommand({
        ChallengeName: ChallengeNameType.MFA_SETUP,
        ClientId: config.clientId,
        Session: response.Session,
        ChallengeResponses: {
          USERNAME: username,
        },
      });

      const challengeResponse = await cognitoClient.send(challengeCommand);
      const authenticationResult = challengeResponse.AuthenticationResult;

      if (authenticationResult) {
        // Sla de nieuwe tokens op in localStorage
        saveTokens(authenticationResult, rememberMe);
      } else {
        throw new Error("Geen Authentication Result ontvangen van Cognito.");
      }

      return authenticationResult;
    } else {
      throw new Error("Er is iets misgegaan bij het instellen van uw MFA.");
    }
  } catch (error) {
    console.error("Fout bij het verifiëren van de MFA-code:", error);
    throw error;
  }
};
