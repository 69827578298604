// context/RecordingContext.js
import React, { createContext, useState, useEffect, useRef } from "react";
import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as ChakraButton,
  Text,
} from "@chakra-ui/react";
import { getJuvolySessionId, addConversation, updateConversation, addMetadataConversation } from "api/lawdiskService";
import { ensureValidToken } from "services/authService";
import { useHistory } from "react-router-dom";

// Define the shape of the context
const RecordingContext = createContext();

export const RecordingProvider = ({ children }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [conversation, setConversation] = useState("");
  const [conversationId, setConversationId] = useState(null);
  const conversationIdRef = useRef(conversationId);
  const [buffer, setBuffer] = useState("");
  const [webSocket, setWebSocket] = useState(null);
  const [mediaStream, setMediaStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [model, setModel] = useState("generic");
  const [isStopping, setIsStopping] = useState(false);
  const [waitingForSetup, setWaitingForSetup] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toast = useToast();
  const history = useHistory();
  const unblockRef = useRef(null);

  useEffect(() => {
    conversationIdRef.current = conversationId;
  }, [conversationId]);

  useEffect(() => {
    const handleConversationUpdates = async () => {
      if (conversationIdRef.current === null && conversation.split(" ").length > 9) {
        const response = await addConversation(conversation);
        setConversationId(response.id);
      }
      else if (conversationIdRef.current !== null) {
        await updateConversation(conversationIdRef.current, conversation, null);
      }
    }

    const interval = setInterval(handleConversationUpdates(), 1 * 5 * 1000);
    return () => clearInterval(interval);
  }, [conversation]);

  // Prevent navigation within the app during recording
  useEffect(() => {
    if (isRecording) {
      unblockRef.current = history.block((tx) => {
        setIsModalOpen(true);
        return false;
      });
    } else if (unblockRef.current) {
      unblockRef.current();
      unblockRef.current = null;
    }

    return () => {
      if (unblockRef.current) {
        unblockRef.current();
        unblockRef.current = null;
      }
    };
  }, [isRecording, history]);

  // Warn user before refreshing or closing the tab during recording
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isRecording) {
        event.preventDefault();
        event.returnValue = ""; // This triggers the browser's confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isRecording]);

  // Function to check token expiry
  const checkTokenExpiry = (token) => {
    if (!token) return true;

    const payload = JSON.parse(atob(token.split(".")[1]));
    const expiryTime = payload.exp * 1000;

    return Date.now() > expiryTime;
  };

  // Start Recording Function
  const startRecording = async (selectedDeviceId = null, isV2 = false) => {
    if (isRecording) return;

    setIsDisabled(true);
    setWaitingForSetup(true);
    try {
      const juv_session = await getJuvolySessionId(isV2);
      if (!juv_session.sessionId) {
        throw new Error("Kan session-ID niet ophalen.");
      }

      const ws_url = isV2
        ? `wss://services.juvoly.nl/api/v2/socket/${model}`
        : `wss://ai.juvoly.nl/ws/speech/lawdisk/${juv_session.sessionId}`;

      const subprotocols = isV2
        ? ["v2.juvoly", juv_session.clientId, juv_session.sessionId]
        : undefined;

      const ws = new WebSocket(ws_url, subprotocols);
      setWebSocket(ws);

      ws.onopen = () => {
        navigator.mediaDevices
          .getUserMedia({ audio: { deviceId: selectedDeviceId } })
          .then((stream) => {
            setMediaStream(stream);
            const recorder = new MediaRecorder(stream);
            setMediaRecorder(recorder);

            recorder.ondataavailable = ({ data }) => {
              if (ws.readyState === WebSocket.OPEN) {
                ws.send(data);
              }
            };

            recorder.start(250);
            setIsRecording(true);
            setWaitingForSetup(false);
            setIsDisabled(false);
          })
          .catch((error) => {
            toast({
              title: "Fout",
              description:
                "Geef a.u.b. toegang tot de microfoon. Ververs de pagina om het opnieuw te proberen.",
              status: "error",
              duration: 6000,
              isClosable: true,
            });
            console.error("Microphone access error:", error);
            setIsRecording(false);
            setWaitingForSetup(false);
            setIsDisabled(false);
          });
      };

      ws.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result.type === "transcript") {
          if (isV2) {
            // Handle v2 structure
            if (result.utterance) {
              if (result.completed) {
                // Append completed utterances to the conversation
                setConversation((prev) =>
                  prev.trim().concat(` ${result.utterance.sentence}`)
                );
                setBuffer("");
              } else {
                // Update buffer with the ongoing utterance
                setBuffer(result.utterance.sentence);
              }
            }
          } else {
            // Handle v1 structure
            result.transcript.completed.forEach((completed) => {
              setConversation((prev) =>
                prev.trim().concat(` ${completed.sentence}`)
              );
            });
            setBuffer(
              result.transcript.buffer.length > 0
                ? result.transcript.buffer[0].sentence
                : ""
            );
          }
        }
      };

      ws.onclose = () => {
        stopRecording();
      };
    } catch (error) {
      toast({
        title: "Fout",
        description: error.message || "Kan transcriberen niet starten.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
      console.error("Fout bij starten van transcriberen:", error);
      setIsDisabled(false);
      setIsRecording(false);
      setWaitingForSetup(false);
    }
  };

  // Stop Recording Function
  const stopRecording = async () => {
    if (!isRecording) return;

    setIsDisabled(true);
    setIsStopping(true);
    try {
      if (webSocket) {
        webSocket.close();
      }

      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        setMediaRecorder(null);
      }

      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
        setMediaStream(null);
      }

      if (buffer !== "") {
        setConversation((prev) => prev.concat(buffer));
      }

      await ensureValidToken();
      const result = await updateConversation(conversationId, conversation, null);
      addMetadataConversation(conversationId);
      if (result.id !== undefined) {
        showToastConversationAdded();
      }
    } catch (error) {
      console.error("Error saving conversation:", error);
      toast({
        title: "Error",
        description:
          "Er is een fout opgetreden bij het opslaan van de conversatie.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    } finally {
      setIsDisabled(false);
      setIsRecording(false);
      setWebSocket(null);
      setBuffer("");
      setConversation("");
      setIsStopping(false);
      setConversationId(null);
    }
  };

  // Function to show a success toast
  function showToastConversationAdded() {
    toast({
      title: "Conversatie toegevoegd",
      description: "U kunt de transcriptie nu terug zien in uw overzicht.",
      status: "info",
      duration: 6000,
      isClosable: true,
    });
  }

  return (
    <RecordingContext.Provider
      value={{
        isRecording,
        buffer,
        conversation,
        startRecording,
        stopRecording,
        model,
        setModel,
        isDisabled,
        setConversation,
        isStopping,
        waitingForSetup,
        mediaStream,
      }}
    >
      {children}

      {/* Modal for navigation blocking */}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transcriberen bezig</ModalHeader>
          <ModalBody>
            <Text>
              U bent momenteel aan het transcriberen. Stop de transcriptie
              voordat u verdergaat om te voorkomen dat u uw voortgang verliest.
            </Text>
          </ModalBody>
          <ModalFooter>
            <ChakraButton
              colorScheme="red"
              onClick={() => setIsModalOpen(false)}
            >
              Blijf
            </ChakraButton>
            {/* Optional: Add a button to stop recording and proceed */}
            <ChakraButton
              colorScheme="green"
              ml={3}
              onClick={async () => {
                await stopRecording();
                setIsModalOpen(false);
                // Optionally, navigate away programmatically
              }}
            >
              Stop transcriberen en verdergaan
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </RecordingContext.Provider>
  );
};

export default RecordingContext;
