import {
  FormLabel,
  Input,
  InputGroup,
  List,
  ListItem,
  ListIcon,
  Collapse,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import React from "react";

function EmailInput({ email, onEmailChange, showValidationEmail }) {
  const handleInputChange = (e) => {
    const lowerCaseEmail = e.target.value.toLowerCase();
    if (onEmailChange) {
      onEmailChange(lowerCaseEmail);
    }
  };

  const criteria = {
    hasAtSymbol: /@/.test(email),
    hasDomain: /\.[a-z]{2,}$/i.test(email),
    hasNoSpaces: /\s/.test(email) === false,
    startsWithValidChar: /^[^@]+/.test(email),
    validStructure: /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email),
  };

  const isEmailValid = showValidationEmail
    ? Object.values(criteria).every((value) => value)
    : true;

  const isListVisible = email.trim() !== "" && !isEmailValid;

  return (
    <>
      <InputGroup>
        <Input
          borderRadius="15px"
          mb="10px"
          fontSize="sm"
          type="email"
          value={email}
          onChange={handleInputChange}
          placeholder="Uw e-mailadres"
          size="lg"
        />
      </InputGroup>
      <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
        <Collapse in={isListVisible} animateOpacity>
          <List spacing={3}>
            <ListItem>
              <ListIcon as={WarningIcon} color="red.500" />
              Geef een geldig e-mailadres op
            </ListItem>
          </List>
        </Collapse>
      </FormLabel>
    </>
  );
}

export default EmailInput;
