// SignIn.js

// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  Checkbox,
  InputRightElement,
  InputGroup,
  useToast,
  Link,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import PasswordInput from "components/PasswordInput/passwordInput";
import EmailInput from "components/Emailinput/emailInput";
import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { ChallengeNameType } from "@aws-sdk/client-cognito-identity-provider";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// Assets
import { FaEye, FaArrowLeft, FaEyeSlash } from "react-icons/fa";
import cover from "assets/img/cover-auth.png";
import React, { useRef, useState, useEffect } from "react";
import {
  signIn,
  RespondToMfaChallenge,
  RespondToNewPasswordChallenge,
  VerifyMfaCode,
  initiateForgotPassword,
  confirmForgotPassword,
} from "services/authService";
import QRCode from "qrcode.react";
import { set } from "date-fns";
import SocialLogin from "components/Sociallogin/SocialLogin";
function SignIn() {
  const basicStepTab = useRef();
  const mfaStepTab = useRef();
  const newPasswordStepTab = useRef();
  const forgotPasswordTab = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [signInError, setSignInError] = useState("");
  const [cognitoSession, setCognitoSession] = useState("");
  const [mfaCode, setMfaCode] = useState(new Array(6).fill(""));
  const [mfaSetupQrUrl, setMfaSetupQrUrl] = useState("");
  const [mfaSecretcode, setMfaSecretcode] = useState("");
  const [mfaSignInError, setMfaSignInError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [mfaSetupError, setMfaSetupError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const handlePasswordChange = (newPassword, isValid) => {
    setPassword(newPassword);
    setCanProceed(isValid);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const toast = useToast();

  const emailInputRef = useRef(null);
  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const inputRefsSignIn = useRef([...Array(6)].map(() => useRef(null)));
  const inputRefsSetup = useRef([...Array(6)].map(() => useRef(null)));

  const handleNavigation = () => {
    window.location.href = "/auth/sign-up"; // doorgaan naar de inlogpagina
  };

  useEffect(() => {
    if (mfaStepTab.current && cognitoSession) {
      if (inputRefsSignIn.current[0] && inputRefsSignIn.current[0].current) {
        inputRefsSignIn.current[0].current.focus(); // Set focus to the first input
      }
    }
  }, [cognitoSession, mfaStepTab]);

  const handleChangeSignIn = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);

      if (value && index < inputRefsSignIn.current.length - 1) {
        inputRefsSignIn.current[index + 1].current.focus();
      }
    }
  };

  const handleChangeSetup = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newMfaCode = [...mfaCode];
      newMfaCode[index] = value;
      setMfaCode(newMfaCode);

      if (value && index < inputRefsSetup.current.length - 1) {
        inputRefsSetup.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownSignIn = (e, index) => {
    if (e.key === "Backspace" && mfaCode[index] === "" && index > 0) {
      inputRefsSignIn.current[index - 1].current.focus();
    }
  };

  const handleKeyDownSetup = (e, index) => {
    if (e.key === "Backspace" && mfaCode[index] === "" && index > 0) {
      inputRefsSetup.current[index - 1].current.focus();
    }
  };

  const handlePasteSignIn = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newMfaCode = pastedData.split("");
      setMfaCode(newMfaCode);

      // Move focus to the last input
      inputRefsSignIn.current[5].current.focus();
    }
  };

  const handlePasteSetup = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newMfaCode = pastedData.split("");
      setMfaCode(newMfaCode);

      // Move focus to the last input
      inputRefsSetup.current[5].current.focus();
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const response = await signIn(email, password);
      const { ChallengeName, Session, AuthenticationResult } = response;

      if (AuthenticationResult) {
        // Direct doorsturen als er geen uitdaging is
        console.log("Succesvolle login zonder MFA");
        window.location.href = "/";
        return;
      }

      if (ChallengeName === ChallengeNameType.SOFTWARE_TOKEN_MFA) {
        setCognitoSession(Session);
        mfaStepTab.current.click();
      }

      if (ChallengeName === ChallengeNameType.NEW_PASSWORD_REQUIRED) {
        setCognitoSession(Session);
        setPassword("");
        newPasswordStepTab.current.click();
      }
    } catch (error) {
      setSignInError(error.message);
    }
  };

  const handleSignInMfa = async (e) => {
    e.preventDefault();
    const mfaCodeString = mfaCode.join("");

    try {
      const session = await RespondToMfaChallenge(
        email,
        mfaCodeString,
        cognitoSession,
        rememberMe
      );
      if (session && typeof session.AccessToken !== "undefined") {
        window.location.href = "/admin/dashboard/notulen";
      }
    } catch (error) {
      setMfaSignInError(error.message);
    }
  };

  const [mfaChoice, setMfaChoice] = useState(null);

  const handleNewPassword = async (e) => {
    e.preventDefault();

    // Reset error messages
    setNewPasswordError("");
    setPassword("");

    try {
      // Send the new password to Cognito
      const response = await RespondToNewPasswordChallenge(
        email,
        password,
        cognitoSession
      );

      if (response && response.AuthenticationResult) {
        // Save tokens if needed
        // For example: localStorage.setItem('accessToken', response.AuthenticationResult.AccessToken);
        // Redirect the user to the secured page
        window.location.href = "/";
      } else {
        // If there is no AuthenticationResult, show an error message
        setNewPasswordError(
          "Authenticatie mislukt na het instellen van het nieuwe wachtwoord."
        );
      }
    } catch (error) {
      setNewPasswordError(error.message || "Er is een fout opgetreden.");
    }
  };

  // --- Forgot Password States ---
  // Deze usestate laat zien of de wachtwoorden overeen komen
  const [canProceed, setCanProceed] = useState(false);

  const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
  const [forgotPasswordCode, setForgotPasswordCode] = useState(
    new Array(6).fill("")
  );
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const inputRefsForgotPassword = useRef([...Array(6)].map(() => useRef(null)));

  // Handlers for Forgot Password
  const handleInitiateForgotPassword = async (e) => {
    e.preventDefault();
    setForgotPasswordError("");
    // setForgotPasswordSuccess("");
    // test push
    try {
      await initiateForgotPassword(email);
      // setForgotPasswordSuccess("Verificatiecode verzonden naar uw e-mail.");
      toast({
        title: "Verificatiecode verzonden naar uw e-mail.",
        // description: "Het notulen Word-document is succesvol gegenereerd.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setForgotPasswordStep(2);
      // Focus on the first code input
      if (inputRefsForgotPassword.current[0].current) {
        inputRefsForgotPassword.current[0].current.focus();
      }
    } catch (error) {
      console.log(error);
      setForgotPasswordError(
        error.message || "Fout bij het initiëren van wachtwoordherstel."
      );
    }
  };

  const handleChangeForgotPasswordCode = (value, index) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...forgotPasswordCode];
      newCode[index] = value;
      setForgotPasswordCode(newCode);

      if (value && index < inputRefsForgotPassword.current.length - 1) {
        inputRefsForgotPassword.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDownForgotPassword = (e, index) => {
    if (
      e.key === "Backspace" &&
      forgotPasswordCode[index] === "" &&
      index > 0
    ) {
      inputRefsForgotPassword.current[index - 1].current.focus();
    }
  };

  const handlePasteForgotPassword = (e) => {
    const pastedData = e.clipboardData.getData("Text").trim();

    if (/^\d{6}$/.test(pastedData)) {
      const newCode = pastedData.split("");
      setForgotPasswordCode(newCode);

      // Move focus to the last input
      inputRefsForgotPassword.current[5].current.focus();
    }
  };

  const handleConfirmForgotPassword = async (e) => {
    e.preventDefault();
    setForgotPasswordError("");
    // setForgotPasswordSuccess("");

    const verificationCode = forgotPasswordCode.join("");

    // Check if passwords match
    if (canProceed === false) {
      setForgotPasswordError("Wachtwoorden komen niet overeen.");
      return;
    }

    try {
      await confirmForgotPassword(email, verificationCode, password);
      // setForgotPasswordSuccess("Wachtwoord succesvol gewijzigd. U kunt nu inloggen.");
      toast({
        title: "Wachtwoord succesvol gewijzigd",
        description: "U kunt nu inloggen.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      // Optionally, redirect to sign-in
      // Reset the forgot password state
      setForgotPasswordStep(1);
      setForgotPasswordCode(new Array(6).fill(""));
      setPassword("");
      // Switch back to the sign-in tab
      basicStepTab.current.click();
    } catch (error) {
      setForgotPasswordError(
        error.message || "Fout bij het bevestigen van nieuw wachtwoord."
      );
    }
  };

  const titleColor = useColorModeValue("blue.300", "blue.200");
  const textColor = useColorModeValue("gray.400", "white");

  // Back button
  const tooltipBg = useColorModeValue("gray.300", "gray.700");
  const buttonBg = useColorModeValue("gray.200", "gray.600");
  const buttonHoverBg = useColorModeValue("gray.300", "gray.500");
  const iconColor = useColorModeValue("gray.800", "white");
  return (
    <Flex position="relative">
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: "100px", md: "0px" }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{ md: "150px", lg: "80px" }}
          >
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Welkom bij het LawDisk™ portaal.
            </Heading>
            <Text ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
              Vul uw gegevens in.
            </Text>
            <Flex w="100%">
              <Tabs w="100%">
                <TabList
                  display="none"
                  align="center"
                  alignSelf="center"
                  justifySelf="center"
                >
                  <Tab ref={basicStepTab}></Tab>
                  <Tab ref={mfaStepTab}></Tab>
                  <Tab ref={newPasswordStepTab}></Tab>
                  <Tab ref={forgotPasswordTab}></Tab>{" "}
                </TabList>
                <TabPanels
                  variant="unstyled"
                  mt="24px"
                  display="flex"
                  flexDirection="column"
                >
                  {/* Eerste TabPanel: Inloggen */}
                  <TabPanel>
                    <form>
                      <SocialLogin />
                      <FormControl isInvalid={signInError}>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Email
                        </FormLabel>
                        <EmailInput
                          email={email}
                          onEmailChange={handleEmailChange}
                          showValidationEmail={false}
                        />
                        <FormErrorMessage mb="10px" fontSize="md">
                          {signInError}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          Wachtwoord
                        </FormLabel>
                        <PasswordInput
                          password={password}
                          onPasswordChange={handlePasswordChange}
                          showValidation={false}
                        />
                        <FormErrorMessage mb="10px"></FormErrorMessage>
                      </FormControl>
                      {/* Forgot Password Link */}
                      <Text
                        fontSize="sm"
                        color={titleColor}
                        fontWeight="bold"
                        cursor="pointer"
                        onClick={() => {
                          forgotPasswordTab.current.click();
                          setForgotPasswordStep(1); // Reset forgot password step
                          setEmail(email); // Optionally pre-fill email
                          setForgotPasswordCode(new Array(6).fill(""));
                          setForgotPasswordError("");
                          // setForgotPasswordSuccess("");
                        }}
                        textAlign="right"
                      >
                        Wachtwoord vergeten?
                      </Text>
                      <FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleSignIn}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          LOG IN
                        </Button>
                        <Text
                          color={textColor}
                          fontWeight="medium"
                          align="center"
                        >
                          Heeft u nog geen account?
                          <Link
                            onClick={handleNavigation}
                            color={titleColor}
                            as="span"
                            ms="5px"
                            fontWeight="bold"
                          >
                            Account aanmaken
                          </Link>
                        </Text>
                        <FormErrorMessage>{signInError}</FormErrorMessage>
                      </FormControl>
                    </form>
                  </TabPanel>

                  {/* Tweede TabPanel: 2FA Code */}
                  <TabPanel width="100%">
                    <form w="100%">
                      <FormControl isInvalid={mfaSignInError}>
                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                          2FA Code
                        </FormLabel>

                        <Flex justify="center" mb="24px">
                          {mfaCode.map((_, index) => (
                            <Input
                              key={index}
                              ref={inputRefsSignIn.current[index]}
                              value={mfaCode[index]}
                              onChange={(e) =>
                                handleChangeSignIn(e.target.value, index)
                              }
                              onKeyDown={(e) => handleKeyDownSignIn(e, index)}
                              onPaste={(e) => handlePasteSignIn(e)}
                              maxLength={1}
                              inputMode="numeric"
                              pattern="\d*"
                              textAlign="center"
                              fontSize="2xl"
                              width="50px"
                              height="50px"
                              borderColor="gray.300"
                              _focus={{ borderColor: "blue.300" }}
                              _hover={{ borderColor: "gray.500" }}
                              mx="5px"
                            />
                          ))}
                        </Flex>
                        <FormControl
                          display="flex"
                          alignItems="center"
                          mb="36px"
                        >
                          <Checkbox
                            isChecked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            colorScheme="blue"
                          >
                            Onthoud mij voor 14 dagen op dit apparaat
                          </Checkbox>
                        </FormControl>
                        <FormErrorMessage>{mfaSignInError}</FormErrorMessage>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          onClick={handleSignInMfa}
                          _hover={{ bg: "blue.200" }}
                          _active={{ bg: "blue.400" }}
                        >
                          LOG IN
                        </Button>
                      </FormControl>
                    </form>
                  </TabPanel>
                  {/* Derde TabPanel: Nieuwe Wachtwoord */}
                  <TabPanel>
                    <form>
                      <FormControl isInvalid={newPasswordError}>
                        <PasswordInput
                          password={password}
                          onPasswordChange={handlePasswordChange}
                          showValidation={true}
                        />
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          isDisabled={!canProceed}
                          onClick={handleNewPassword}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          LOG IN
                        </Button>
                      </FormControl>
                    </form>
                  </TabPanel>
                  {/* Vierde TabPanel: Forgot Password */}
                  <TabPanel>
                    <Flex
                      position="absolute"
                      top="100px"
                      left="40px"
                      zIndex="10"
                    >
                      <Tooltip
                        hasArrow
                        label="Ga terug"
                        bg={tooltipBg}
                        color={useColorModeValue("black", "white")}
                      >
                        <IconButton
                          icon={<FaArrowLeft />}
                          aria-label="Terug"
                          color={iconColor}
                          borderRadius="50%"
                          boxSize="40px"
                          bg={buttonBg}
                          _hover={{ bg: buttonHoverBg }}
                          onClick={() =>
                            (window.location.href = "/auth/sign-in")
                          }
                        />
                      </Tooltip>
                    </Flex>
                    <Flex
                      position="absolute"
                      top="100px"
                      left="40px"
                      zIndex="10"
                    >
                      <Tooltip
                        hasArrow
                        label="Ga terug"
                        bg={tooltipBg}
                        color={useColorModeValue("black", "white")}
                      >
                        <IconButton
                          icon={<FaArrowLeft />}
                          aria-label="Terug"
                          color={iconColor}
                          borderRadius="50%"
                          boxSize="40px"
                          bg={buttonBg}
                          _hover={{ bg: buttonHoverBg }}
                          onClick={() =>
                            (window.location.href = "/auth/sign-in")
                          }
                        />
                      </Tooltip>
                    </Flex>

                    {forgotPasswordStep === 1 && (
                      <>
                        <form>
                          <Text
                            mb="36px"
                            color={textColor}
                            fontWeight="bold"
                            fontSize="14px"
                          >
                            Voer je e-mailadres om een verificatiecode te
                            ontvangen
                          </Text>
                          <FormControl isInvalid={forgotPasswordError}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="normal"
                              w="100"
                            >
                              Email
                            </FormLabel>
                            <EmailInput
                              email={email}
                              onEmailChange={handleEmailChange}
                              showValidationEmail={true}
                            />
                            <FormErrorMessage mb="10px">
                              {forgotPasswordError}
                            </FormErrorMessage>
                          </FormControl>
                          <Button
                            fontSize="sm"
                            type="submit"
                            bg="blue.300"
                            w="100%"
                            h="45"
                            mb="20px"
                            color="white"
                            mt="20px"
                            onClick={handleInitiateForgotPassword}
                            _hover={{
                              bg: "blue.200",
                            }}
                            _active={{
                              bg: "blue.400",
                            }}
                          >
                            VERZEND VERIFICATIECODE
                          </Button>
                        </form>
                        <Flex>
                          <Text color={textColor} fontWeight="medium">
                            Terug naar
                          </Text>
                          <Link
                            onClick={() => basicStepTab.current?.click()}
                            color={titleColor}
                            as="span"
                            ms="5px"
                            fontWeight="bold"
                          >
                            Inloggen
                          </Link>
                        </Flex>
                      </>
                    )}

                    {forgotPasswordStep === 2 && (
                      <form>
                        <FormControl isInvalid={forgotPasswordError}>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            Verificatiecode
                          </FormLabel>
                          <Flex mb="24px" maxW="100%">
                            {forgotPasswordCode.map((_, index) => (
                              <Input
                                padding="0"
                                lineHeight="normal"
                                key={index}
                                ref={inputRefsForgotPassword.current[index]}
                                value={forgotPasswordCode[index]}
                                onChange={(e) =>
                                  handleChangeForgotPasswordCode(
                                    e.target.value,
                                    index
                                  )
                                }
                                onKeyDown={(e) =>
                                  handleKeyDownForgotPassword(e, index)
                                }
                                onPaste={(e) => handlePasteForgotPassword(e)}
                                maxLength={1}
                                inputMode="numeric"
                                pattern="\d*"
                                textAlign="center"
                                width="37px"
                                height="50px"
                                borderColor="gray.300"
                                _focus={{ borderColor: "blue.300" }}
                                _hover={{ borderColor: "gray.500" }}
                                mx="5px"
                              />
                            ))}
                          </Flex>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            <PasswordInput
                              password={password}
                              onPasswordChange={handlePasswordChange}
                              showValidation={true}
                            />
                            <FormErrorMessage mb="10px">
                              {forgotPasswordError}
                            </FormErrorMessage>
                          </FormLabel>
                        </FormControl>
                        <Button
                          fontSize="sm"
                          type="submit"
                          bg="blue.300"
                          w="100%"
                          h="45"
                          mb="20px"
                          color="white"
                          mt="20px"
                          isDisabled={!canProceed}
                          onClick={handleConfirmForgotPassword}
                          _hover={{
                            bg: "blue.200",
                          }}
                          _active={{
                            bg: "blue.400",
                          }}
                        >
                          Bevestig Wachtwoord
                        </Button>
                      </form>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={cover}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
